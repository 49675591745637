import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.scss";
import Home from "./Pages/index";
import About from "./Pages/about/About";
import GetInvolved from "./Pages/getinvolved/GetInvolved";
import GreenJobs from "./Pages/greenjobs/greenjobs";
import CleanEnergy from "./Pages/energysolutions/energysolutions";
import CommunityEngagement from "./Pages/communityengagement/communityengagement";
function App() {
  return (
    <div className="App">
     <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/about' element={<About/>} />
    <Route path='/getinvolved' element={<GetInvolved/>} />
    <Route path='/greenjobs' element={<GreenJobs/>} />
    <Route path='/cleanenergy' element={<CleanEnergy/>} />
    <Route path='/communityengagement' element={<CommunityEngagement/>} />

     </Routes>
    </div>
  );
}

export default App;
