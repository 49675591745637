import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom"; // If using React Router
import EmpowerLogoWhite from "../Images/EMPOWERLOGO_White.png";

const Footer = () => {
  const goToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="footer">
      <Container fluid>
        <Row xs={12} className="logo-links-container">
          <Col xs={12} lg={3} className="footer-logo-container">
            <Link to="/">
              <img
                onClick={goToTop}
                className="footer-logo"
                src={EmpowerLogoWhite}
                alt="Empower Logo in White Color"
              />
            </Link>
          </Col>
          <Col xs={12} lg={3} className="footer-links-container">
            <ul className="footer-links-ul">
              <li>
                <Link
                  onClick={goToTop}
                  to="/getinvolved"
                  className="footer-link-text"
                >
                  Get Involved
                </Link>
              </li>
              <li>
                <Link
                  onClick={goToTop}
                  to="/about"
                  className="footer-link-text"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={goToTop}
                  to="/greenjobs"
                  className="footer-link-text"
                >
                 Green Jobs
                </Link>
              </li>
              <li>
                <Link
                  onClick={goToTop}
                  to="/cleanenergy"
                  className="footer-link-text"
                >
               Clean Energy Solutions
                </Link>
              </li>
              <li>
                <Link
                  onClick={goToTop}
                  to="/communityengagement"
                  className="footer-link-text"
                >
               Community Engagement
                </Link>
              </li>
         
            </ul>
          </Col>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <hr className="footer-divider" />
          </Row>
        </Row>

  {/*       <Row xs={12}>
          <Col className="social-media-icons-container">
            <i class="bi bi-facebook"></i>
            <i class="bi bi-twitter-x"></i>
            <i class="bi bi-instagram"></i>
            <i class="bi bi-envelope"></i>
          </Col>
        </Row> */}

        <Row xs={12}>
          <Col className="copyright-container">
            <p>
              <small>
                &copy; {new Date().getFullYear()} EMPOWER Coalition Alachua County
              </small>
            </p>
          </Col>
        </Row>
      </Container>

      {/* <div className="container p-4">
        <div className="row">
          <div className="col-lg-4 col-md-12 mb-4">
            <h5 className="text-uppercase">About Us</h5>
            <p>
              We are a company dedicated to providing the best services to our customers.
            </p>
          </div>

          <div className="col-lg-4 col-md-6 mb-4">
            <h5 className="text-uppercase">Quick Links</h5>
            
          </div>

          
        </div>
      </div>

      <div className="text-center p-3 bg-light">
    
      </div> */}
    </footer>
  );
};

export default Footer;
