import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";

import Navigation from "../../Components/Navigation";
import Footer from "../../Components/Footer";

import empowergraphic from '../../Images/cleanempower-graphic-crop.jpg'
export default function CleanEnergy() {
  useEffect(() => {
    window.scrollTo(0, 0);
       document.title="Clean Energy Solutions"
  }, []);
  return (
    <main>
      <Navigation />

      {/* CODE FOR THE MOVEMENT SECTION*/}
      <section>
        <Grid size={12} container className="energy-container">
          <Grid
            container
            size={12}
            justifyContent="center"
          
          >
            <Grid
              size={{ xs: 12, md: 12 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <h1>Building a Sustainable Future Together</h1>
            </Grid>
          </Grid>
        
        </Grid>
      </section>

      {/* CODE FOR THE WEATHERIZATION SECTION*/}
      <section>
      <Grid
          container
          size={12}
          justifyContent="center"
          className="pipeline-container"
        >
          <Grid container size={12} justifyContent="center">
            <h1 textAlign="center">Weatherization </h1>
          </Grid>
          <Grid size={{ xs: 12, lg: 10 }} style={{ paddingTop: "3%" }}>
            <p>
            Project EMPOWER is committed to improving the energy efficiency of homes and buildings in Alachua County. Through various initiatives, EMPOWER aims to reduce energy consumption, lower energy bills, and create more comfortable living spaces.
            </p>
            <ul className="pipeline-list">
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Home Energy Tune-Ups: </span>
                EMPOWER offers free home energy Tune-Ups to identify areas where energy is being wasted. These assessments provide personalized recommendations for improvements, such as sealing air leaks, upgrading insulation, and replacing inefficient appliances.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Weatherization Assistance Programs:  </span>
                EMPOWER works with local partners to provide financial assistance and resources to help residents implement recommended energy-efficiency upgrades.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                Community Outreach:{" "}
                </span>
                EMPOWER conducts outreach campaigns to raise awareness about the benefits of weatherization and to encourage residents to participate in energy-saving programs.
              </li>
            </ul>
            <p>
            By investing in weatherization, EMPOWER is helping residents save money on their energy bills, reduce their carbon footprint, and create healthier, more comfortable homes. 
            </p>
          </Grid>
        </Grid>
   
      </section>


<section>
      <Grid
          container
          size={12}
          justifyContent="center"
          className="impact-container"
        >
          <Grid container size={10} justifyContent="center">
            <h1 textAlign="center">Solar Power: Redefining Community Solar </h1>
         
          </Grid>
         
          <Grid container  size={{ xs: 12, lg: 12 }} style={{ paddingTop: "3%", justifyContent:'center' }}>
          <Grid container size={{xs:12, sm:12, md:10}} className='innovative-title' >
      
            <h4 >EMPOWER's Innovative Approach </h4>
          <p>EMPOWER is pioneering a new model for community solar that aims to extend its benefits to marginalized and energy-burdened communities in Alachua County. Recognizing the limitations of traditional community solar programs, EMPOWER proposes a Solar for All Community Fund that leverages the power of institutional energy consumers to address the root causes of energy inequity. </p>
       


    <Grid container size={12}justifyContent='center' alignContent='center'flexDirection='column'>
      <Grid container size={12} justifyContent='center'>
      <h2 className="empower-graphic-text">EMPOWER Coalition Community-Led Fund and Services</h2>
      </Grid>
      <Grid container size={12} justifyContent='center'>
          <img className='empower-graphic' src={empowergraphic}  alt="Chart of EMPOWER Coalition Community-Led Fund and Services" />
        </Grid>
          </Grid>
          </Grid>
       
          <Grid size={{xs:12, md:6}} style={{paddingTop:'3%'}}>
       <h4>The Problem</h4>
       
          <ul className="pipeline-list">
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>Limited Access:{" "}</span>
                Marginalized and energy-burdened communities often face barriers to accessing solar energy, such as renting rather than owning their homes, having pressing energy needs better addressed through conservation and weatherization, and facing trade-offs between shade-cooling trees and solar panels.
              </li>
              <li className="pipeline-spacing">
                <span style={{ fontWeight: "bold" }}>
                Limited Benefits: {" "}
                </span>
                Traditional community solar programs often benefit a single family more than a neighborhood. Furthermore, regulatory restrictions in Florida limit the potential for community solar to generate significant revenue.
              </li>
         
            </ul>
            </Grid>

            <Grid container size={{xs:12, md:6}} style={{paddingTop:'3%', flexDirection:'column'}}>
       <h4>The Solution</h4>

       <p ><strong>EMPOWER's proposed Solar for All Community Fund addresses these challenges by: </strong></p>
       
          <ul className="pipeline-list">
              <li className="pipeline-spacing">
              <span style={{ fontWeight: "bold" }}>
              Partnering with Institutional Energy Consumers:{" "}
                </span>
            By enlisting institutional energy consumers, the fund can leverage the long-term cost avoidance from net metered rates as a source of funding. 
              </li>
              <li className="pipeline-spacing">
              <span style={{ fontWeight: "bold" }}>
              Supporting Community-Led Initiatives:{" "}
                </span>
             The fund will provide resources to address the root causes of energy burden in homes, including energy efficiency improvements and green job training. 
              </li>
              <li className="pipeline-spacing">
              <span style={{ fontWeight: "bold" }}>
              Creating Resilience Hubs:{" "}
                </span>
               Grant funds will be used to establish neighborhood Resilience Hubs to address the increasing impacts of climate change and disruptive storm events. 
              </li>
            </ul>
            </Grid>
          </Grid>
       
        </Grid>
</section>
    {/* CODE FOR THE DONATION SECTION*/}
    <section>
      <Grid
          container
          size={12}
          justifyContent="center"
          className="pipeline-container"
        >
          <Grid container size={12} justifyContent="center">
            <h1 textAlign="center">The Proposed Solar "Leasing" Program </h1>
          </Grid>
          <Grid size={{ xs: 12, lg: 10 }} style={{ paddingTop: "3%" }}>
         
            <ul className="pipeline-list">
              <li className="pipeline-spacing">
              <span style={{ fontWeight: "bold" }}>
              Guaranteed Savings:{" "}
                </span>
             Institutional participants will be guaranteed approximately 10% utility savings through long-term funding agreements with a Solar Leasing Partner. 
              </li>
              <li className="pipeline-spacing">
              <span style={{ fontWeight: "bold" }}>
              Funding the Community Fund: {" "}
                </span>
             A portion of the savings will be contributed to the Solar for All Community Fund, providing a sustainable source of funding for community-led initiatives. 
              </li>
           
            </ul>
            <p >
            By implementing this innovative approach, EMPOWER aims to ensure that the benefits of solar energy are shared equitably among all residents in Alachua County, regardless of their socioeconomic status or housing situation. 
            </p>
          </Grid>
        </Grid>
   
      </section>

      <Footer />
    </main>
  );
}
