import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Button } from "react-bootstrap";
import { Link } from '@mui/material';
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import storyimg from "../Images/Capture.PNG";
export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
       document.title="Home"
  }, []);
  return (
    <main>
      <Navigation />
      {/* CODE FOR THE HERO SECTION*/}
      <section>
        <Grid container size={12} className="hero-section">
          <Grid
            size={{ xs: 12, sm: 12, md: 12, lg: 12 }}
            container
            className="first-container"
          >
            <h1>Empowering Alachua County for a Just and Sustainable Future</h1>
          </Grid>
          <Grid container size={12} className="last-container">
            <Grid
              size={{ xs: 10, md: 8, lg: 8, xl: 8 }}
              className=" empower-text"
            >
              <h3  style={{ fontSize: "24px", textAlign: "center" }}>
                EMPOWER is committed to creating a just and sustainable future
                for Alachua County by promoting clean energy, addressing energy
                inequity, and fostering economic opportunities for all.
              </h3>
            </Grid>
          </Grid>
        </Grid>
      </section>
      {/* CODE FOR THE OUR STORY SECTION*/}
      <section>
        <Grid container className="about-container">
          <Grid
            container
            item
            size={12}
            justifyContent="center"
            style={{ marginBottom: "2%" }}
          >
            <h1>Our Story</h1>
          </Grid>

          <Grid item size={{ xs: 12, lg: 8 }} className="story-img-container">
            <img
              className="story-img"
              src={storyimg}
              alt="Volunteers sitting in a room during a meeting"
            />
          </Grid>

          <Grid
            item
            size={{ xs: 12, md: 12, lg: 10 }}
            className="story-description"
          >
            <p className="empower-description">
              Project EMPOWER, a cornerstone of the EMPOWER Coalition, emerged
              from a 2021 NAACP forum focused on transitioning Alachua County to
              100% clean energy. Recognizing the need for a just and equitable
              transition, EMPOWER was established to address energy inequity,
              create green job opportunities, and promote sustainable energy
              solutions.
            </p>
          </Grid>

          <Grid
            item
            xs={12}
            lg={8}
            size={{ xs: 12, lg: 8 }}
            className="button-container"
          >
            <Link href="/communityengagement" underline="none">
      <Button className="story-button">READ MORE</Button>
    </Link>
          </Grid>
        </Grid>
      </section>

      {/* CODE FOR THE SUSTAINABLE SECTION*/}
      <section>
        <Grid container className="sustainable-container">
          <Grid
            size={{ xs: 12, lg: 10 }}
            style={{ marginTop: "2%", marginBottom: "2%", textAlign: "center" }}
          >
            <h1>Building a Sustainable Future Together</h1>
          </Grid>
          <Grid size={{ xs: 12, md: 10, lg: 10 }} justifyContent="left">
        
              <Grid size={{ xs: 12,md:12, lg: 7 }} className="empower-blurb">
                <p className="empower-description">
                  Project EMPOWER is actively working to implement clean energy
                  solutions that can benefit both the environment and the
                  community. By focusing on weatherization and solar power,
                  EMPOWER is striving to reduce energy consumption, lower costs,
                  and improve the overall quality of life for residents in
                  Alachua County.
                </p>
              </Grid>
              <Grid
                size={{ xs: 12, lg: 7 }}
                display="flex"
                justifyContent="center"
                style={{ marginBottom: "1%" }}
              >
              <Link href="/cleanenergy" underline="none">
      <Button className="story-button">READ MORE</Button>
    </Link>
              </Grid>
    
          </Grid>
        </Grid>
      </section>

      <Footer />
    </main>
  );
}
